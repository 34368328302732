var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "100%" },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "w-100 h-100" },
            [
              _c(
                "v-icon",
                { staticClass: "close-icon mt-2", on: { click: _vm.hide } },
                [_vm._v(" close ")]
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "pa-10",
                  staticStyle: {
                    height: "90vh",
                    "padding-top": "0 !important",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "charts d-flex align-center justify-center pa-3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mb-0 body-2 grey--text" },
                        [
                          _c(
                            "div",
                            { staticClass: "pr-0 custom-small-chip" },
                            [
                              _c("label", { staticClass: "label-text" }, [
                                _vm._v(_vm._s(_vm.$t("labels.selectDs"))),
                              ]),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.datasetsList,
                                  "item-text": "label",
                                  attach: "",
                                  dense: "",
                                  outlined: "",
                                  "no-data-text": _vm.$t("dropdowns.noData"),
                                  "return-object": "",
                                  multiple: "",
                                  "persistent-hint": "",
                                },
                                on: {
                                  change: _vm.changeSelectedDatasets,
                                  input: _vm.limiter,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-chip",
                                          {
                                            attrs: {
                                              close: true,
                                              small: "",
                                              "text-color": "white",
                                              color: item.borderColor,
                                            },
                                            on: {
                                              "click:close": function ($event) {
                                                return _vm.removeChipByLabel(
                                                  item.label
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item.label))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.selectedDatasets,
                                  callback: function ($$v) {
                                    _vm.selectedDatasets = $$v
                                  },
                                  expression: "selectedDatasets",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("line-chart", {
                            style: _vm.popupStyle,
                            attrs: {
                              chartdata: _vm.chartdata,
                              options: _vm.options,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }