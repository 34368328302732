





















































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import LineChart from '../chartjs/LineChart.vue';
export default Vue.extend({
  components: {
    LineChart,
  },
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },
    tabInfo: {
      type: String,
    },
   
  },
  data() {
    return {
      visible1: false,
      selectedDatasets: [],
      datasetsList: [],
      popChartData: [],
      persistDataset: {},
      updateChartData:this.chartdata
    };
  },
  watch: {
    visible: function (data) {
      if (data == true) this.visible1 = true;
      else this.visible1 = false;
    },
    chartdata: function (newChartData) {
      // Update selectedDatasets and datasetsList when chartdata changes
      if (newChartData) {
        this.selectedDatasets = newChartData.datasets.map((dataset) => ({
          label: dataset.label,
          borderColor: dataset.borderColor,
          borderWidth: dataset.borderWidth,
          data: dataset.data,
          fill: dataset.fill,
          yAxisID: dataset.yAxisID,
          _meta: dataset._meta,
        }));
        this.datasetsList = newChartData.datasetsList || [];
        this.datasetsList = this.datasetsList.filter((set)=>set.label != "Strategy Timeline");
      }
    },
  },
  methods: {
    limiter(e) {
      e = e.filter(set => set.label !== 'Strategy Timeline')
      if(e.length > 5) {
        this.$notify.error("You can select upto 5 datasets only")
        this.selectedDatasets.pop()
      }
    },
    setYaxis(datasets) {
      let yAxes = [];
      datasets.map((data) => {
        yAxes.push({
          type: 'linear',
          position: 'left',
          gridLines: {
            display: true,
            color: data.borderColor,
            drawOnChartArea: false,
          },
          //max: Math.max(data.data)
          id: data.label,
        });
      });
      this.options['scales'] = {
        yAxes: yAxes,
      };
    },
    removeChipByLabel(label: string) {
      this.selectedDatasets = this.selectedDatasets.filter(
        (item: { label: string }) => item.label !== label
      );
      this.changeSelectedDatasets();
    },
    emitData(data) {
      this.$emit('hidePopup', data);
    },
    hide() {
      this.visible1 = false;
      this.chartData = [];
      this.updateChartData = [];
      this.popChartData = [];
      this.popChartData.push({
        visible: this.visible1,
        chartdata: this.updateChartData,
      });
      this.emitData(this.popChartData);
    },
    changeSelectedDatasets(): void {
      this.setYaxis(this.selectedDatasets);
      this.updateChartData = {
        labels: this.chartdata.labels,
        datasets: this.selectedDatasets,
        datasetsList: this.datasetsList,
      };
      this.popChartData = [];
      this.popChartData.push({
        visible: this.visible1,
        chartdata: this.updateChartData,
      });
      this.emitData(this.popChartData);
    },
  },
  computed: {
    popupStyle() {
      return {
        height: 'calc(100vh - 270px)',
        //width:0.7*screen.width +'px',
        position: 'relative',
      };
    },
  },
});
